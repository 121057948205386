<template>
    <v-data-table
        :headers="fullPreviewColumnHeaders"
        :items="previewFetching ? [] : previewData"
        :loading="previewFetching"
        item-key="short_id"
        :sort-desc="[true, true]"
        hide-default-footer
        class="text-truncate"
    >
        <template v-slot:top>
            <div style="height:56px" class="d-flex align-center">
                <v-icon>mdi-table-large</v-icon>
                <span class="primary--text font-weight-bold subtitle-1 ml-1">{{ currentTableSelected }}</span>
            </div>
        </template>
        <template v-slot:no-data>
            <v-alert text prominent color="info" icon="info" class="mt-3">
                <span v-if="previewFetching">Loading column data, please wait...</span>
                <span v-else>No data available.</span>
            </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'snapshot-table-preview',
    props: {
        currentTableSelected: String
    },
    computed: {
        ...mapState('snapshotStore', ['previewFetching', 'previewData', 'previewColumnHeaders']),
        fullPreviewColumnHeaders() {
            var headers = []
            this.previewColumnHeaders.forEach(row => {
                headers.push({ text: row, align: 'left', value: row })
            })
            return headers
        }
    }
}
</script>
