var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "text-truncate",
    attrs: {
      headers: _vm.fullPreviewColumnHeaders,
      items: _vm.previewFetching ? [] : _vm.previewData,
      loading: _vm.previewFetching,
      "item-key": "short_id",
      "sort-desc": [true, true],
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "d-flex align-center",
                staticStyle: { height: "56px" }
              },
              [
                _c("v-icon", [_vm._v("mdi-table-large")]),
                _c(
                  "span",
                  {
                    staticClass:
                      "primary--text font-weight-bold subtitle-1 ml-1"
                  },
                  [_vm._v(_vm._s(_vm.currentTableSelected))]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "no-data",
        fn: function() {
          return [
            _c(
              "v-alert",
              {
                staticClass: "mt-3",
                attrs: { text: "", prominent: "", color: "info", icon: "info" }
              },
              [
                _vm.previewFetching
                  ? _c("span", [_vm._v("Loading column data, please wait...")])
                  : _c("span", [_vm._v("No data available.")])
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }